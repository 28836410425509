import * as React from "react";
import { useEffect, useRef } from "react";
import TradingView, {
  widget,
  ChartingLibraryWidgetOptions,
  ResolutionString,
  CustomTimezoneId,
} from "../../lib/charting_library";
import { useTheme } from "../../utils/ThemeProvider";
import Datafeed from "./AdvanceChartDataFeed";
import dayjs from "dayjs";
import Breadcrumb from "../shared/Breadcrumb";
import useMarketStatus from "../../hooks/useMarketStatus";

const advChartNavigation = [
  { label: "Dashboard", link: "/dashboard" },
  { label: "Charts", link: "/dashboard" },
  { label: "Advanced Chart", link: "/charts/advanced-charts" },
];
export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  // interval: ChartingLibraryWidgetOptions["interval"];
  datafeedUrl: any;
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  container: ChartingLibraryWidgetOptions["container"];
}

const AdvancedChart = (props: { userId: string }) => {
  const marketStatus = useMarketStatus();
  const [chartHeight, setChartHeight] = React.useState(window.innerHeight);
  const { theme } = useTheme();
  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const defaultProps: Omit<ChartContainerProps, "container"> = {
    symbol: `BANKNIFTY-I`,
    // interval: '3' as ResolutionString,
    datafeedUrl: Datafeed,
    libraryPath: "/charting_library/",
    fullscreen: true,
    autosize: true,
  };

  useEffect(() => {
    const topBarHeight = document.querySelector("header")?.clientHeight || 0;

    const updateChartHeight = () => {
      setChartHeight(window.innerHeight - topBarHeight - 100);
    };

    updateChartHeight();

    window.addEventListener("resize", updateChartHeight);

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: defaultProps.symbol as string,
      datafeed: Datafeed(props.userId, marketStatus),
      interval: "3" as ResolutionString,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,

      locale: "en",
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      client_id: "tradingview.com",
      user_id: "public_user_id",
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      theme: theme,
      // height: 600,
      timezone: "Asia/Kolkata",
      studies_overrides: {},
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      const chart = tvWidget.chart();
      tvWidget.headerReady().then(() => {
        // tvWidget.activeChart().setResolution();
        // const button = tvWidget.createButton();
        // button.setAttribute("title", "Trade History");
        // button.classList.add("trade-history-button");
        // button.addEventListener("click", trueDataAuth);
        // button.innerHTML = "Show Trade History";
        // tvWidget.chart().createStudy(
        //   "Volume",
        //   false,
        //   false,
        //   {},
        //   {
        //     "title.text": "OSPL Volume",
        //   }
        // );
        tvWidget.chart().createStudy(
          "Moving Average Exponential",
          false,
          false,
          { length: 20 },
          { "plot.color.0": "#FFA500", title: "EMA 20" } // Color for EMA 20
        );
        tvWidget.chart().createStudy(
          "Moving Average Exponential",
          false,
          false,
          { length: 50 },
          { "plot.color.0": "#FFD700" } // Color for EMA 50
        );
        tvWidget.chart().createStudy(
          "Moving Average Exponential",
          false,
          false,
          { length: 100 },
          { "plot.color.0": "#FF69B4" } // Color for EMA 100
        );
        tvWidget.chart().createStudy(
          "Moving Average Exponential",
          false,
          false,
          { length: 200 },
          { "plot.color.0": "#8A2BE2", title: "EMA 200" } // Color for EMA 200
        );
        // tvWidget.chart().createStudy(
        //   "VWAP",
        //   false,
        //   false,
        //   {
        //     showPrice: true,
        //     showLabels: true,
        //     priceScale: "left",
        //     deviation: 0,
        //     session: "day",
        //     hlc3: true,
        //   },
        //   {
        //     "plot.color.0": "#FF4500",
        //   }
        // );
        // tvWidget.chart().createStudy(
        //   "Moving Average Weighted",
        //   false,
        //   false,
        //   { length: 20 },
        //   {
        //     "plot.color.0": "#1E90FF",
        //   }
        // );
        // tvWidget.chart().createStudy(
        //   "Supertrend",
        //   false,
        //   false,
        //   { factor: 10, ATRPeriod: 2 },
        //   {
        //     "plot.color.0": "#32CD32",
        //     priceScale: "right",
        //   }
        // );
        // const hasPreviousData = false;
        // let lastDataTimestamp: number = dayjs().unix();
        // if (!hasPreviousData) {
        //   const today = dayjs();
        //   const startOfDayIST = today
        //     .startOf("day")
        //     .add(9, "hours")
        //     .add(15, "minutes")
        //     .unix();
        //   const resolutionMinutes = 3;
        //   const currentTimeIST = lastDataTimestamp + resolutionMinutes * 60;
        //   const chart = tvWidget.chart();
        //   chart.setVisibleRange({
        //     from: startOfDayIST,
        //     to: currentTimeIST,
        //   });
        // }
      });
    });

    return () => {
      tvWidget.remove();
      window.removeEventListener("resize", updateChartHeight);
    };
  }, [props.userId, marketStatus]);

  return (
    <>
      <div className="mt-3">
        <div className="mb-1">
          <Breadcrumb items={advChartNavigation} />
        </div>
        <div
          ref={chartContainerRef}
          id="tv_chart_container"
          className="TVChartContainer min-h-screen"
          // style={{ height: 100 }}
        />
      </div>
    </>
  );
};

export default AdvancedChart;
