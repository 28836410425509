import dayjs from "dayjs";
import { getLiveMarketTimeData } from "../../api/dashboard";
import { getIndexSubscription } from "../../api/futures/open-interest";
import {
  fetchHistoricAdvData,
  fetchSymbolData,
  futuresOptionDataType,
  generateHistoricToIdentifer,
  generateToIdentifer,
  generateTopicName,
  historicParseData,
  liveParseData,
  parseData,
  timeSelection,
} from "./helper";

const lastBarsCache = new Map();
let pollingIntervals = new Map();
const intervalMap = new Map();

const datafeed = (userId: string, marketStatus: string) => {
  const configurationData = {
    supported_resolutions: timeSelection,
    exchanges: [
      {
        value: "NSE",
        name: "NSE",
        desc: "National Strock Exchange",
      },
    ],
    symbols_types: [
      {
        name: "All",
        value: "All",
      },
      {
        name: "Futures",
        value: "futures",
      },
      {
        name: "Options",
        value: "options",
      },
    ],
  };

  async function getAllSymbols() {
    const data = await fetchSymbolData();
    let allSymbols = [];
    if (data.Futures && data.Futures.length > 0) {
      const futuresSymbol = data.Futures.map(
        (future: futuresOptionDataType) => {
          return {
            symbol: future.Name,
            full_name: future.Name,
            description: future.Value,
            productName: future.Product,
            exchange: configurationData.exchanges[0].value,
            type: configurationData.symbols_types[1].value,
          };
        }
      );
      allSymbols.push(...futuresSymbol);
    }
    if (data.Options && data.Options.length > 0) {
      const optionsSymbol = data.Options.map(
        (option: futuresOptionDataType) => {
          return {
            symbol: option.Name,
            full_name: option.Name,
            description: option.Value,
            productName: option.Product,
            exchange: configurationData.exchanges[0].value,
            type: configurationData.symbols_types[2].value,
          };
        }
      );
      allSymbols.push(...optionsSymbol);
    }
    return allSymbols;
  }

  return {
    onReady: (callback: Function) => {
      setTimeout(
        () =>
          callback({
            supports_marks: false,
            supports_timescale_marks: false,
            supports_time: true,
          }),
        0
      );
    },
    searchSymbols: async (
      userInput: any,
      exchange: any,
      symbolType: any,
      onResultReadyCallback: any
    ) => {
      const symbols = await getAllSymbols();
      const newSymbols = symbols.filter((symbol) => {
        const isExchangeValid = exchange === "" || symbol.exchange === exchange;
        const isFullSymbolContainsInput =
          symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !==
          -1;
        return isExchangeValid && isFullSymbolContainsInput;
      });
      onResultReadyCallback(newSymbols);
    },

    resolveSymbol: async (
      symbolName: any,
      onSymbolResolvedCallback: any,
      onResolveErrorCallback: any
    ) => {
      const symbols = await getAllSymbols();
      const symbolItem = symbols.find(
        ({ full_name }) => full_name === symbolName
      );
      if (!symbolItem) {
        onResolveErrorCallback("Symbol not found");
        return;
      }

      const symbolInfo = {
        ticker: symbolItem.full_name,
        name: symbolItem.symbol,
        description: symbolItem.full_name,
        type: symbolItem.type,
        session: "0000-2359:1234567",
        timezone: "Asia/Kolkata",
        exchange: symbolItem.exchange,
        supported_resolutions: configurationData.supported_resolutions,
        minmov: 1,
        pricescale: 100,
        has_intraday: true,
        visible_plots_set: "ohlc",
        has_weekly_and_monthly: false,
        volume_precision: 2,
        data_status: "streaming",
        toIdentifier: symbolItem.description,
      };
      onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (
      symbolInfo: any,
      resolution: any,
      periodParams: any,
      onHistoryCallback: any,
      onErrorCallback: any
    ) => {
      const { from, to, firstDataRequest } = periodParams;
      const topicName = generateTopicName(symbolInfo.toIdentifier);
      const toIdentifier = generateToIdentifer(
        symbolInfo.toIdentifier,
        resolution,
        symbolInfo.name
      );
      const toHistoricIdentifier = generateHistoricToIdentifer(
        symbolInfo.toIdentifier,
        resolution,
        symbolInfo.name
      );
      let allBars: any = [];
      let historicalBars: any = [];
      const toDate = new Date(to * 1000);
      const lastDateTime = dayjs(toDate).subtract(276, "d").toDate();
      const modifiedFrom = lastDateTime.getTime() / 1000;
      let retryCount = 0;
      const maxRetries = 5;
      const daysAgo = Math.floor((modifiedFrom - to) / 86400);
      const fetchHistoricBarData = async () => {
        try {
          const rawData = await fetchHistoricAdvData(
            topicName,
            toHistoricIdentifier,
            Math.abs(daysAgo).toString()
          );
          const parsedData = historicParseData(rawData);
          parsedData.forEach((bar) => {
            if (bar.time >= modifiedFrom && bar.time < to) {
              historicalBars.push({
                time: bar.time * 1000,
                open: bar.open,
                high: bar.high,
                low: bar.low,
                close: bar.close,
              });
            }
          });
          historicalBars = historicalBars.sort(
            (a: any, b: any) => a.time - b.time
          );
          allBars = [...historicalBars];
          if (marketStatus === "closed" || marketStatus === "pre-open") {
            onHistoryCallback(allBars, { noData: allBars.length === 0 });
          }
        } catch (error) {
          onErrorCallback(error);
        }
      };
      const fetchBarData = async () => {
        try {
          await getIndexSubscription(
            topicName,
            userId ? userId : "",
            toIdentifier
          );
          const response = await getLiveMarketTimeData(topicName, toIdentifier);
          if (!response || !response.length) {
            if (retryCount < maxRetries) {
              retryCount++;
              setTimeout(fetchBarData, 2000);
            } else {
              onHistoryCallback([], { noData: true });
            }
            return;
          }
          const parsedData = parseData(response);
          let liveBars: any = [];
          parsedData.forEach((bar) => {
            if (bar.time >= from && bar.time < to) {
              liveBars.push({
                time: bar.time * 1000,
                open: bar.open,
                high: bar.high,
                low: bar.low,
                close: bar.close,
              });
            }
          });
          if (allBars.length > 0) {
            const lastBarTime = allBars[allBars.length - 1].time;
            liveBars = liveBars.filter((bar: any) => bar.time > lastBarTime);
          }
          allBars = [...allBars, ...liveBars];
          onHistoryCallback(allBars, { noData: allBars.length === 0 });
        } catch (error) {
          onErrorCallback(error);
        }
      };

      if (firstDataRequest) {
        await fetchHistoricBarData();
        if (historicalBars.length > 0) {
          lastBarsCache.set(symbolInfo.full_name, {
            ...historicalBars[historicalBars.length - 1],
          });
        }
      }

      // fetchBarData();
      // if (intervalMap.has(symbolInfo.ticker)) {
      //   clearInterval(intervalMap.get(symbolInfo.ticker));
      // }
      // let intervalId: any;
      // if (marketStatus === "open" || marketStatus === "after-open") {
      //   intervalId = setInterval(fetchBarData, 2000);
      // }
      // intervalMap.set(symbolInfo.ticker, intervalId);
      if (marketStatus === "open") {
        if (intervalMap.has(symbolInfo.ticker)) {
          clearInterval(intervalMap.get(symbolInfo.ticker));
        }
        const intervalId = setInterval(fetchBarData, 2000);
        intervalMap.set(symbolInfo.ticker, intervalId);
      } else if (marketStatus === "after-open") {
        await fetchBarData();
      }
    },

    clearIntervalForSymbol: (symbolTicker: string) => {
      if (intervalMap.has(symbolTicker)) {
        clearInterval(intervalMap.get(symbolTicker));
        intervalMap.delete(symbolTicker);
      }
    },

    subscribeBars: async (
      symbolInfo: any,
      resolution: any,
      onRealtimeCallback: any,
      subscribeUID: any,
      onResetCacheNeededCallback: any
    ) => {
      const topicName = generateTopicName(symbolInfo.toIdentifier);
      const identifer = generateToIdentifer(
        symbolInfo.toIdentifier,
        resolution,
        symbolInfo.name
      );
      const toHistoricIdentifier = generateHistoricToIdentifer(
        symbolInfo.toIdentifier,
        resolution,
        symbolInfo.name
      );
      let bars: any[] = [];

      if (pollingIntervals.has(subscribeUID)) {
        clearInterval(pollingIntervals.get(subscribeUID));
      }
      await getIndexSubscription(topicName, userId ? userId : "", identifer);
      try {
        if (marketStatus === "closed" || marketStatus === "pre-open") {
          let rawData = null;
          for (let days = 1; days <= 3; days++) {
            try {
              rawData = await fetchHistoricAdvData(
                topicName,
                toHistoricIdentifier,
                days.toString()
              );
              if (rawData && rawData.length > 0) {
                console.log("Data fetched");
                break;
              }
            } catch (error) {
              console.error("Error fetching the data", error);
            }
          }
          if (rawData) {
            bars = liveParseData(rawData);
            if (bars && bars.length > 0) {
              const lastBar = bars[bars.length - 1];
              onRealtimeCallback(lastBar);
              pollingIntervals.set(subscribeUID, lastBar.time);
            }
          } else {
            console.log("No data after 3 days tries");
          }
        } else if (marketStatus === "open") {
          const intervalId = setInterval(async () => {
            try {
              const liveResponse = await getLiveMarketTimeData(
                topicName,
                identifer
              );
              const liveBars = liveParseData(liveResponse);
              if (liveBars && liveBars.length > 0) {
                const lastLiveBar = liveBars[liveBars.length - 1];
                onRealtimeCallback(lastLiveBar);
              }
            } catch (error) {
              console.error("Error polling live market data:", error);
            }
          }, 1000);

          pollingIntervals.set(subscribeUID, intervalId);
        } else if (marketStatus === "after-open") {
          const response = await getLiveMarketTimeData(topicName, identifer);
          bars = liveParseData(response);
          if (bars && bars.length > 0) {
            const lastBar = bars[bars.length - 1];
            onRealtimeCallback(lastBar);
            pollingIntervals.set(subscribeUID, lastBar.time);
          }
        }
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    },

    unsubscribeBars: (subscriberUID: any) => {
      console.log(
        "[unsubscribeBars]: Method call with subscriberUID:",
        subscriberUID
      );
      clearInterval(pollingIntervals.get(subscriberUID));
      pollingIntervals.delete(subscriberUID);
    },
  };
};

export default datafeed;
