import axios from "axios";
import useInstrumentStrikePrice from "../store/instrumentExpiryStrikePrice";
import { tokenType } from "../types";
import { getValidToken } from "./helper";

const baseApi = axios.create({
  // test environment
  // baseURL: "https://tradedons-api.facillima.com",
  // baseURL: "https://tradedons-test-api.azurewebsites.net",
  // Production azure environment
  // baseURL: "https://tradedons-api.azurewebsites.net"
  // UTHO URL
  // text env
  // baseURL: "http://103.150.136.189:70",
  // prod env
  baseURL: "https://tradedons.com:8443",
});

export default baseApi;

//test environment
// export const baseURL = "https://tradedons-api.facillima.com"
// export const baseURL = "https://tradedons-test-api.azurewebsites.net"
// production azure environment
// export const baseURL = "https://tradedons-api.azurewebsites.net"
// UTHO URL
// text env
// export const baseURL = "http://103.150.136.189:70";
// prod env
export const baseURL = "https://tradedons.com:8443";

// mitss1687@gmail.com
// Mitali@123 ya 4

// mitali.mishra@facillimatech.com

export const COMMON_HEADERS = {
  "Content-Type": "application/json",
};

let interceptor: any;

export const setupAxiosInstance = (token: tokenType) => {
  if (interceptor) return;
  interceptor = axios.interceptors.request.use(
    async (req) => {
      const validTokens = await getValidToken(token);
      req = {
        ...req,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        headers: {
          ...COMMON_HEADERS,
          Authorization: `Bearer ${validTokens.accessToken}`,
        },
      };
      return req;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const removeAxiosInstance = () => {
  if (interceptor) {
    axios.interceptors.request.eject(interceptor);
    interceptor = null;
  }
};
