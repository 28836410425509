import { differenceInMinutes, differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";
import { getHolidayData } from "../api/dashboard";

function getMarketOpenTime() {
  return new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    3,
    44,
    59
  );
}

function getMarketCloseTime() {
  return new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    10,
    0
  );
}

function getMarketAfterOpenStartTime() {
  return new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    10,
    1
  );
}

function getMarketNewCloseTime() {
  return new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    16,
    30
  );
}

function getMarketPreOpenStartTime() {
  return new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    3,
    30
  );
}

function getMarketPreOpenCloseTime() {
  return new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    3,
    44,
    58
  );
}

export default function useMarketStatus() {
  const [marketStatus, setMarketStatus] = useState<
    "closed" | "open" | "pre-open" | "loading" | "after-open"
  >("loading");
  const [holidayData, setHolidayData] = useState<Record<string, any>[]>([]);

  const currentYearHoliday = async () => {
    const year = new Date().getFullYear();
    const response = await getHolidayData(year.toString());
    setHolidayData(response);
  };

  useEffect(() => {
    currentYearHoliday();
  }, []);

  useEffect(() => {
    let isHolidayToday: any;
    if (holidayData.length > 0) {
      isHolidayToday = holidayData.find(
        (item) => item.HolidayDate === new Date().toISOString().split("T")[0]
      );
    }
    const intervalId = setInterval(() => {
      const date = new Date(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours() + 5,
        new Date().getUTCMinutes() + 30,
        new Date().getUTCSeconds()
      );
      const currentDay = date.getDay();
      if (isHolidayToday) {
        if (isHolidayToday.ExceptionalTime !== "") {
          const [startTime, endTime] =
            isHolidayToday.ExceptionalTime.split("-");
          const startDate = new Date(
            `${isHolidayToday.HolidayDate}T${startTime}`
          );
          const endDate = new Date(`${isHolidayToday.HolidayDate}T${endTime}`);
          if (date >= startDate && date <= endDate) {
            setMarketStatus("open");
          } else {
            setMarketStatus("closed");
          }
        } else {
          setMarketStatus("closed");
        }
      } else {
        const now = new Date(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(),
          new Date().getUTCDate(),
          new Date().getUTCHours(),
          new Date().getUTCMinutes(),
          new Date().getUTCSeconds()
        );
        if (currentDay === 0 || currentDay === 6) {
          setMarketStatus("closed");
        } else if (
          differenceInSeconds(now, getMarketOpenTime()) >= 0 &&
          differenceInSeconds(now, getMarketCloseTime()) <= 0
        ) {
          setMarketStatus("open");
        } else if (
          differenceInSeconds(now, getMarketPreOpenStartTime()) >= 0 &&
          differenceInSeconds(now, getMarketPreOpenCloseTime()) <= 0
        ) {
          setMarketStatus("pre-open");
        } else if (differenceInSeconds(now, getMarketOpenTime()) === 0) {
          setMarketStatus("open");
        } else if (differenceInMinutes(now, getMarketPreOpenStartTime()) < 0) {
          setMarketStatus("closed");
        } else if (
          differenceInMinutes(now, getMarketAfterOpenStartTime()) >= 0 &&
          differenceInMinutes(now, getMarketNewCloseTime()) <= 0
        ) {
          setMarketStatus("after-open");
        } else if (differenceInMinutes(now, getMarketCloseTime()) > 0) {
          setMarketStatus("closed");
        }
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [holidayData]);
  return marketStatus;
}
