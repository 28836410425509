import React from "react";
import AdvancedChart from "../../components/advanced-charts/AdvancedChart";
import AppLayout from "../../layout/AppLayout";
import { useGetFromStore } from "../../hooks/useGetFromStore";
import useAuthStore from "../../store/auth";

export default function AdvancedChartPage() {
  const userId = useGetFromStore(useAuthStore, (state) => state.user.id);

  return (
    <AppLayout>
      <AdvancedChart userId={userId} />
    </AppLayout>
  );
}
