import { baseURL } from "../../api/base";

export type symbolDataType = {
  Futures: any[];
  Options: any[];
};

export type futuresOptionDataType = {
  Name: string;
  Product: string;
  Value: string;
};

export const fetchSymbolData = async () => {
  try {
    const res = await fetch(
      `${baseURL}/api/advchart/GetFuturesAndOptionsSymbolsForAdvChart`
    );
    if (res.status === 200) {
      const data = await res.json();
      return data as symbolDataType;
    }
  } catch (error) {
    console.error("Error fetching Symbols", error);
  }
  return { Futures: [], Options: [] };
};

export const fetchHistoricAdvData = async (
  topicName: string,
  identifier: string,
  days: string
) => {
  try {
    const res = await fetch(
      `${baseURL}/api/advchart/GetAdvChartData?TopicName=${topicName}&Identifier=${identifier}&daysAgo=${days}`
    );
    if (res.status === 200) {
      const data = await res.json();
      return data;
    }
  } catch (error) {
    console.error("Error fetching the data", error);
  }
};

export const futureOptions = [
  {
    name: "Futures",
    value: "futures",
  },
  {
    name: "Options",
    value: "options",
  },
];
export const indexStocks = ["BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTY"];
export const timeSelection = [
  "1",
  "3",
  "5",
  "10",
  "15",
  "30",
  "60",
  "1D",
  "1W",
  "1M",
];

export const generateTopicName = (symbolName: string) => {
  const splitItem = symbolName.split("_");
  return `ADV_${splitItem[0]}_${splitItem[1]}`;
};

// {
//   "Name": "BANKNIFTY-I",
//   "Value": "FUTIDX_BANKNIFTY_30Oct2024_0_3Min",
//   "Product": "BANKNIFTY"
// },
// {
//   "Name": "BANKNIFTY24103051600CE",
//   "Value": "OPTIDX_BANKNIFTY_30Oct2024_CE_51600_3Min",
//   "Product": "BANKNIFTY"
// },
export const generateHistoricToIdentifer = (
  symbolName: string,
  time: string,
  name: string
) => {
  const splitItem = symbolName.split("_");
  if (splitItem[0] === "FUTIDX") {
    const splitMonth = name.split("-");
    if (splitMonth[1] === "I") {
      return `ADV_${splitItem[0]}_${splitItem[1]}_CurrentMonth_0_${time}Min`;
    } else if (splitMonth[1] === "II") {
      return `ADV_${splitItem[0]}_${splitItem[1]}_NextMonth_0_${time}Min`;
    } else {
      return `ADV_${splitItem[0]}_${splitItem[1]}_FarMonth_0_${time}Min`;
    }
  } else {
    return `ADV_${splitItem[0]}_${splitItem[1]}_${splitItem[2]}_${
      // splitItem[3] === "CE" || splitItem[3] === "PE" ? splitItem[4] : "0"
      splitItem[4]
    }_${time}Min`;
  }
};

export const generateToIdentifer = (
  symbolName: string,
  time: string,
  name: string
) => {
  const splitItem = symbolName.split("_");
  return `ADV_${splitItem[0]}_${splitItem[1]}_${splitItem[2]}_${
    splitItem[3] === "CE" || splitItem[3] === "PE" ? splitItem[4] : "0"
  }_${time}Min`;
};

export function historicParseData(response: any) {
  const messages = response.split("\n") as string[];
  const bars = messages.map((item) => {
    const splitItem = item.split(",");

    let from = splitItem[0];
    const fromTimestamp = Date.parse(from) / 1000;
    return {
      time: fromTimestamp,
      open: parseFloat(splitItem[1]),
      high: parseFloat(splitItem[2]),
      low: parseFloat(splitItem[3]),
      close: parseFloat(splitItem[4]),
      conversionSymbol: "",
      conversionType: "force_direct",
      volumefrom: 0,
      volumeto: 0,
    };
  });
  const filteredBar = bars.filter(
    (bar) => !isNaN(bar.open) && bar.open !== 0 && bar.low !== 0
  );
  return filteredBar;
}

export function parseData(response: any) {
  const messages = response.split("\n") as string[];
  const bars = messages.map((item) => {
    const splitItem = item.split(",");
    // const barTimeRange = splitItem[0].split("-");
    let from = splitItem[0];
    // const currentDate = new Date().toISOString().split("T")[0];
    // from = `${currentDate}T${from}:00`;
    const fromTimestamp = Date.parse(from) / 1000;
    return {
      // time: parseInt(splitItem[0]),
      time: fromTimestamp,
      open: parseFloat(splitItem[1]),
      high: parseFloat(splitItem[2]),
      low: parseFloat(splitItem[3]),
      close: parseFloat(splitItem[4]),
      conversionSymbol: "",
      conversionType: "force_direct",
      volumefrom: 0,
      volumeto: 0,
    };
  });
  const filteredBar = bars.filter(
    (bar) => !isNaN(bar.open) && bar.open !== 0 && bar.low !== 0
  );
  return filteredBar;
}

export function liveParseData(response: any) {
  const messages = response.split("\n") as string[];
  const bars = messages.map((item) => {
    const splitItem = item.split(",");
    // const barTimeRange = splitItem[0].split("-");
    let from = splitItem[0];
    // const currentDate = new Date().toISOString().split("T")[0];
    // from = `${currentDate}T${from}:00`;
    const fromTimestamp = Date.parse(from) / 1000;
    return {
      time: fromTimestamp * 1000,
      // time: parseInt(splitItem[0]) * 1000,
      open: parseFloat(splitItem[1]),
      high: parseFloat(splitItem[2]),
      low: parseFloat(splitItem[3]),
      close: parseFloat(splitItem[4]),
      conversionSymbol: "",
      conversionType: "force_direct",
      volumefrom: 0,
      volumeto: 0,
    };
  });
  const filteredBar = bars.filter(
    (bar) => !isNaN(bar.open) && bar.open !== 0 && bar.low !== 0
  );
  return filteredBar;
}

// string TopicName, string Identifier,int daysAgo
// api/advchart/GetAdvChartData
